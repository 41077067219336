import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";


//TODO Remember to update the captcha domain (recaptcha) and firebase domain (firebase hosting) 

const firebaseConfig = {
  apiKey: "AIzaSyCOnuYPmS39Jjuo4xLZsqu4lRl-ZyOcp7M",
  authDomain: "web-agreements.firebaseapp.com",
  projectId: "web-agreements",
  storageBucket: "web-agreements.appspot.com",
  messagingSenderId: "613610717644",
  appId: "1:613610717644:web:8bd3d29637267249f11d69",
  measurementId: "G-ZVBBBK3RQK"
};

const app = initializeApp(firebaseConfig);

getAnalytics(app);


initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6LdfDlAoAAAAABas8wrlqc6HlpcAoGHVqS8ddTPz'),
});

export const db = getFirestore(app);
export const auth = getAuth();
export const provider = new GoogleAuthProvider();
export const storage = getStorage(app);