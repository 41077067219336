import { createSlice } from '@reduxjs/toolkit'
import update from '../../../../../../services/updateContract.js'
import store from '../../../../../../../Infrastructure/Store/store'

export const cardsSlice = createSlice({
    name: 'usersWithAccess',
    initialState: {
        value: {}
    },
    reducers: {

        addUserWithAccess: (state, action) => {
            const UserWithAccessId = Date.now()
            state.value ??= {}
            state.value[UserWithAccessId] = { ...action.payload, id: UserWithAccessId }


            update({
                id: state.contractId,
                usersWithAccess: [{
                    ...action.payload,
                    isNew: true,
                    id: UserWithAccessId,
                }]
            })
                .then(() => {
                    setTimeout(() => store.dispatch(updateUserWithAccess({ id: UserWithAccessId, emailStatus: 'sent' })), 1000)
                })
                .catch(() => {
                    store.dispatch(updateUserWithAccess({ id: UserWithAccessId, emailStatus: 'failed' }))
                })
        },

        deleteUserWithAccess: (state, action) => {

            const UserWithAccessId = action.payload.id
            state.value ??= {}
            delete state.value[UserWithAccessId]


            update({
                id: state.contractId,
                usersWithAccess: [{
                    id: UserWithAccessId,
                    delete: true
                }]
            })

        },

        updateUserWithAccess: (state, action) => {

            const { id } = action.payload || {}
            state.value[id] = { ...state.value[id], ...action.payload }

            update({
                id: state.contractId,
                usersWithAccess: [
                    action.payload
                ]
            })
        },

        setUsersWithAccess: (state, action) => {
            state.value = action.payload || {}
        },

    },
})

export const {
    addUserWithAccess,
    deleteUserWithAccess,
    updateUserWithAccess,
    setUsersWithAccess
} = cardsSlice.actions

export default cardsSlice
