import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import LoadingPage from "../../Static Pages/UtilPages/LoadingPage.js";

const Dashboard = lazy(() => import('../../Contracts/Dashboard/index.js'))
const EditContract = lazy(() => import('../../Contracts/EditContract/index.js'))
const FAQ = lazy(() => import('../../Static Pages/FQA/index.js'))
const TermsOfService = lazy(() => import('../../Static Pages/TermsOfService/index.js'))
const Home = lazy(() => import('../../Static Pages/Home/index.js'))
const Login = lazy(() => import('../../Auth/Login/index.js'))
const SignUp = lazy(() => import('../../Auth/Register/index.js'))
const WithNav = lazy(() => import('../../Nav/index.js'))
const WithFooterOnly = lazy(() => import('../../Nav/WithFooterOnly.js'))
const WithSideBar = lazy(() => import('../../Nav/WithSideBar.js'))
const Templates = lazy(() => import('../../Contracts/Templates/index.js'))
const NotFoundPage = lazy(() => import('../../Static Pages/UtilPages/NoFound/index.js'))
const Verify = lazy(() => import('../../Contracts/Certificates'))
const Services = lazy(() => import('../../Services/index.js'))
const Plan = lazy(()=> import('../../Services/Plans/index.js'))
const VerifyEmail = lazy(() => import('../../Auth/VerifyEmail/index.js'))


export default function index() {
    return (
        <BrowserRouter>
            <Suspense fallback={<LoadingPage />}>
                <Routes>
                    <Route path="/" element={<WithNav><Home /></WithNav>} />
                    <Route path="/dashboard" element={<WithSideBar><Dashboard /></WithSideBar>} />
                    <Route path="/templates" element={<WithSideBar><Templates /></WithSideBar>} />
                    <Route path="/faq" element={<WithNav> <FAQ /> </WithNav>} />
                    <Route path="/termsofservice" element={<WithNav> <TermsOfService /> </WithNav>} />
                    <Route path="/contract/:id" element={<EditContract />} />
                    <Route path="/signup" element={<WithFooterOnly> <SignUp /> </WithFooterOnly>} />
                    <Route path="/login" element={<WithFooterOnly> <Login /> </WithFooterOnly>} />
                    <Route path="/verify" element={<WithNav> <Verify /> </WithNav>} />
                    <Route path="/verify-email" element={<WithNav> <VerifyEmail /> </WithNav>} />
                    <Route path="/services" element={<WithNav> <Services /> </WithNav>} />
                    <Route path="/services/:plan/:planType" element={<WithNav> <Plan /> </WithNav>} />
                    <Route path="/not-found" element={<WithNav><NotFoundPage /></WithNav>} />
                    <Route path="*" element={<Navigate to="/not-found" replace />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}