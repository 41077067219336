import axios from "axios";
import store from "../../Infrastructure/Store/store";
import { addErrorList } from "../features/errors";

export let useReportAxiosErrors = () => {
  const interceptor = axios.interceptors.response.use(
    function (response) {
      return response;
    },
    /**
     * Intercept any error and saved it in the redux state
     * @param {number} error.response.status - Errors code
     */
    function (error) {

      if( error.response?.data.message === 'No access'){
        // Redirect to login and add  a query for the continue url
        window.location.href = `/login?continueUrl=${window.location.pathname}`;
      }

      store.dispatch(
        addErrorList({
          message: error.response?.data.message ?? "Sin conexión",
          code: error?.response?.status ?? "503",
        })
      );
    }
  );

  return () => {
    axios.interceptors.response.eject(interceptor);
  };
};
