import { createStore, combineReducers } from 'redux'
import contract from '../../Contracts/reducers/features/contracts'
import errors from '../../Errors/features/errors'
import certificate from '../../Contracts/Certificates/features'

const rootReducer = combineReducers({
    contract,
    errors,
    certificate,
});

const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store