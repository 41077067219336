import React from 'react'
import { Spinner } from "@nextui-org/react";


export default function LoadingPage() {
  return (
    <section className='w-100 h-screen flex justify-center items-center pb-3'>
      <Spinner />
      <span className='ml-3 text-primary'>
        Loading...
      </span>
    </section>
  )
}
