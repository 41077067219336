import React from 'react';
import ReactDOM from 'react-dom/client';
import './Infrastructure/Assets/css/index.css'//Infrastructure/Assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './Infrastructure/Store/store'
import { QueryClient, QueryClientProvider } from 'react-query'
import { IntlProvider } from "react-intl";
import { LOCALES } from "./Infrastructure/i18n/locales";
import { messages } from "./Infrastructure/i18n/messages";
import { NextUIProvider } from '@nextui-org/react';
import ReportAxiosErrorsWrapper from './Errors/AxiosInterceptors/ReportAxiosErrorsWrapper'
import Notifications from './Errors/Notifications/Notifications'
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient()
const locale = navigator.language?.substring(0, 2) || 'en';

const initialOptions = {
  clientId: "AQ6MXI7bUv3P-qc4SkbCYD7IY6_5F7gO3ns_9TgNPwU6E8cS5nl8vxdbsWkYtHBAEVJpYX7xJlG1uHAY",
  currency: "USD",
  intent: "subscription",
  vault: true,
};


root.render(

  <NextUIProvider>
    <PayPalScriptProvider options={initialOptions}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <IntlProvider
            messages={messages[locale]}
            locale={locale}
            defaultLocale={LOCALES.ENGLISH}
          >
            <ReportAxiosErrorsWrapper>
              <Notifications />
              <App />
            </ReportAxiosErrorsWrapper>
          </IntlProvider>
        </Provider>
      </QueryClientProvider>
    </PayPalScriptProvider>
  </NextUIProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
