import { createSlice } from '@reduxjs/toolkit'
import updateContractRemote from '../../services/updateContract'
import updateSignature from '../../services/updateSignature.js'
import requirements from '../../EditContract/components/Requeriments/reducers/features/requirements'
import usersWithAccess from '../../../../src/Contracts/EditContract/components/Header/components/Share.js/features/usersWithAccess.js'

export const contract = createSlice({
    name: 'contract',
    initialState: {},
    reducers: {

        setNewContract: (state, action) => {
            state.id = action.payload.id;
            state.position = action.payload.position;
        },

        updateContract: (state, action) => {
            state.allowedIncognitoSignatories = action.payload.allowedIncognitoSignatories
            updateContractRemote({
                id: state.id,
                ...action.payload
            })
        },

        updateStateContract: (state, action) => {
            if (action.payload.position !== undefined) state.position = action.payload.position;
            if (action.payload.size !== undefined) state.size = action.payload.size;
            if (action.payload.isPublic !== undefined) state.isPublic = action.payload.isPublic;
            if (action.payload.allowedIncognitoSignatories !== undefined) state.allowedIncognitoSignatories = action.payload.allowedIncognitoSignatories
        },

        addDraggableInput: (state, action) => {

            const inputId = Date.now()
            state.inputs ??= {}
            state.inputs[inputId] = { ...action.payload, id: inputId }

            updateSignature({
                id: state.id,
                input: {
                    id: inputId,
                    owner: action.payload.owner,
                    isNew: true,
                    ...action.payload
                }
            })

        },

        deleteDraggableInput: (state, action) => {

            const inputId = action.payload.id;
            state.inputs ??= [];
            delete state.inputs[inputId];

            updateSignature({
                id: state.id,
                input: {
                    id: inputId,
                    delete: true
                }
            })

        },

        updateDraggableInput: (state, action) => {

            const { id } = action.payload || {}
            state.inputs[id] = { ...state.inputs[id], ...action.payload }

            updateSignature({
                id: state.id,
                input: action.payload
            })
        },

        setInputs: (state, action) => {
            state.inputs = action.payload || {}
        },

    },
    extraReducers: (builder) => {
        builder
            //TODO put inputs in another slice like requirements and uncoment the code witht the needed editions
            .addMatcher((action) => {
                // verifica si la acción pertenece a 'b'
                return action.type.startsWith(requirements.name) || action.type.startsWith(usersWithAccess.name);
            }, (state, action) => {
                // si la acción pertenece a 'b', delega el manejo del estado de 'b' a su reductor
                if (action.type.startsWith(requirements.name)) {
                    state.requirements = requirements.reducer({ contractId: state.id, ...(state.requirements) || {} }, action);
                }
                if (action.type.startsWith(usersWithAccess.name)) {
                    state.usersWithAccess = usersWithAccess.reducer({ contractId: state.id, ...(state.usersWithAccess) || {} }, action);
                }

            });
    },
})

export const {
    updateContract,
    setNewContract,
    updateStateContract,
    addDraggableInput,
    deleteDraggableInput,
    updateDraggableInput,
    setInputs,
} = contract.actions

export default contract.reducer
