/**
 * This file collects the messages of every page and format them for the internalization provider 
 */
import { messages as faqMessages, name as faqName } from '../../Static Pages/FQA/messages'


const messagesSources = [{ messages: faqMessages, name: faqName }]

export const messages = messagesSources.reduce((acc, source) => {

    let messageBeingBuild = acc

    const languages = Object.keys(source.messages)

    languages.forEach(language => {
        messageBeingBuild[language] ??= {}
        Object.entries((source.messages[language])).forEach(([messageName, messageContent]) => {
            messageBeingBuild[language][source.name + "." + messageName] = messageContent
        })
    })

    return messageBeingBuild

}, {})