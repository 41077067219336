/**
 * This file purpose is to provide internationalzation.
 * So it saves all the messages used in the app and its translations
 */

import { LOCALES } from "../../Infrastructure/i18n/locales";

export const messages = {
    [LOCALES.ENGLISH]: {
        title: "FAQ",
    },
    [LOCALES.SPANISH]: {
        title: "Preguntas Frecuentes",
    }
};

export const name = "FAQ"