import { createSlice } from "@reduxjs/toolkit";

/**
 * A slice for errors that affect multiple components
 */

export const certificateSlice = createSlice({
  name: "certificate",
  initialState: null,
  reducers: {
    /**
     * Add a new error to the error list
     * @param {number[]} state.value -previous errors
     * @param {number} action.payload.code -new error detected
     */
    addCertificate: (state, action) => {
      return action.payload;
    },
  },
});

export const { addCertificate } = certificateSlice.actions;
export default certificateSlice.reducer;
