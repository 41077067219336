import './Infrastructure/Assets/css/loading.css'
import './Infrastructure/Assets/css/App.css';
import './Infrastructure/Assets/css/tags.css';
import './Infrastructure/Assets/css/general.css';
import './Infrastructure/Assets/css/animations.css';
import Routes from './Infrastructure/Routes';

function App() {

  return (
    <Routes />
  );
}

export default App;