import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fromLocalStorage from "../../utilities/fromLocalStorage";


export default function Notifications() {

  const errors = useSelector((state) => state.errors);

  useEffect(() => {

    if (errors?.length > 0) {
      toast.error(errors[errors.length - 1].message);
    }

    // TODO: move this to its own file
    if (
      fromLocalStorage('subscription')?.token
      && fromLocalStorage('subscription')?.subscriptionMessage
    ) {

      const subscription = {
        token: fromLocalStorage('subscription')?.token
      }

      toast.success('Subscription Active!')
      localStorage.setItem('subscription', JSON.stringify(subscription))
    }


  }, [errors]);

  return <ToastContainer />;
}
