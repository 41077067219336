import axios from "axios"
import API from '../../API/index.json'
import { auth } from "../../fire"
import fromLocalStorage from "../../utilities/fromLocalStorage"

export default async function updateContract(contract) {

    const idToken = await auth.currentUser?.getIdToken(fromLocalStorage('user'))
    axios.put(API.url + 'contracts/' + contract.id, contract, { headers: { tokenId: idToken }})
}
