import { createSlice } from '@reduxjs/toolkit'
import update from '../../../../../services/updateContract'

export const cardsSlice = createSlice({
    name: 'requirements',
    initialState: {
        value: {}
    },
    reducers: {

        addRequirement: (state, action) => {
            const RequirementId = Date.now()
            state.value ??= {}
            state.value[RequirementId] = { ...action.payload, id: RequirementId }


            update({
                id: state.contractId,
                requirements: [{
                    ...action.payload,
                    isNew: true,
                    id: RequirementId,
                }]
            })
        },

        deleteRequirement: (state, action) => {

            const RequirementId = action.payload.id
            state.value ??= {}
            delete state.value[RequirementId]


            update({
                id: state.contractId,
                requirements: [{
                    id: RequirementId,
                    delete: true
                }]
            })

        },

        updateRequirement: (state, action) => {

            const { id } = action.payload || {}
            state.value[id] = { ...state.value[id], ...action.payload }

            update({
                id: state.contractId,
                hola: "hi",
                requirements: [
                    action.payload
                ]
            })
        },

        setRequirements: (state, action) => {
            state.value = action.payload || {}
        },

    },
})

export const {
    addRequirement,
    deleteRequirement,
    updateRequirement,
    setRequirements
} = cardsSlice.actions

export default cardsSlice
